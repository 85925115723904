<template>
  <div>
    <BreadCreamb :title="content.title" />
    <div class="container">
      <div class="page-card-view-box">
        <div v-html="content.content" />
      </div>

    </div>

  </div>
</template>

<script>
import BreadCreamb from './site-pages/BreadCreamb.vue'

export default {
  name: 'Terms',
  components: {
    BreadCreamb,
  },
  data() {
    return {

    }
  },
  computed: {
    content() {
      return this.$store.getters['auth/siteContent']('terms')
    },
  },
}
</script>

<style scoped>

</style>
